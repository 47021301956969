import { Form, Input } from "@components";
import { CardHeader, FormLoading } from "@components/common";
import { API } from "@hooks";
import { Fragment, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import SunEditor from "suneditor-react";

const Creation = () => {
  const navigate = useNavigate();
  const { item, find, setItem, loading, setLoading, update, store, progress } =
    API.useApi();
  const { id } = useParams();
  const { search } = useLocation();
  let type = new URLSearchParams(search).get("type");
  let paginate = new URLSearchParams(search).get("paginate");
  useEffect(() => {
    setLoading(true);
    if (id) {
      find("/manager/musics", id);
    } else {
      setItem(null);
      setTimeout(() => {
        setLoading(false);
      }, 10);
    }
  }, [id]);

  return (
    <Fragment>
      <CardHeader
        label={
          type === "video"
            ? id
              ? item?.name
                ? " ویرایش " + item?.name
                : ""
              : "ایجاد موزیک ویدیو جدید"
            : id
            ? item?.name
              ? " ویرایش " + item?.name
              : ""
            : "ایجاد موزیک جدید"
        }
        textButton={type ? "لیست موزیک ویدیو ها" : "لیست موزیک ها"}
        toButton={type ? "/musics/music-videos" : "/musics"}
      />
      {loading ? (
        <FormLoading />
      ) : (
        <div className="my-10 ">
          <Form
            progress={progress}
            formProps={{ className: "flex flex-col" }}
            buttonProps={{
              children: "ثبت اطلاعات",
              className:
                "self-end px-3 py-3 mt-3 text-white bg-primary rounded-lg",
            }}
            onSend={async (data: FormData) => {
              if (id) {
                data.append("_method", "PUT");
                await update("manager/musics", data, id);
                if (type === "video") {
                  if (paginate) {
                    navigate(
                      `/musics/music-videos?type=video&paginate=${paginate}`
                    );
                  } else {
                    navigate(`/musics/music-videos?type=video`);
                  }
                } else {
                  if (paginate) {
                    navigate(`/musics?paginate=${paginate}`);
                  } else {
                    navigate(`/musics`);
                  }
                }
              } else {
                await store("manager/musics", data);
                if (type === "video") {
                  if (paginate) {
                    navigate(
                      `/musics/music-videos?type=video&paginate=${paginate}`
                    );
                  } else {
                    navigate(`/musics/music-videos?type=video`);
                  }
                } else {
                  if (paginate) {
                    navigate(`/musics?paginate=${paginate}`);
                  } else {
                    navigate(`/musics`);
                  }
                }
              }
            }}
          >
            <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 gap-5">
              {type == "video" ? (
                <input type="hidden" name="type" id="" value="video" />
              ) : (
                <input type="hidden" name="type" id="" value="music" />
              )}
              <Input.Defualt
                inputProps={{
                  name: "name",
                  placeholder: "متن را وارد نمایید",
                  defaultValue: item?.name,
                }}
                labelProps={{ children: "نام موزیک" }}
              />
              <Input.Defualt
                inputProps={{
                  name: "slug",
                  placeholder: "متن را وارد نمایید",
                  defaultValue: item?.slug,
                }}
                labelProps={{ children: "آدرس URL" }}
              />
              <Input.Time
                name="publish"
                disableDayPicker={false}
                defaultValue={item?.publish}
                labelProps={{
                  children: "زمان انتشار",
                }}
              />
              {type == "video" ? (
                <>
                  <Input.File
                    inputProps={{
                      name: "urls[normal]",
                      accept: "video/*",
                    }}
                    fileCover={{
                      inputProps: {
                        name: "cover",
                      },
                      onChange: (cover) => {
                        setItem({ cover: cover.url });
                      },
                    }}
                    fileDuration={{
                      inputProps: {
                        name: "duration",
                        defaultValue: item?.duration,
                      },
                    }}
                    labelProps={{ children: "فایل با کیفیت معمولی" }}
                    placeholder="فایل را انتخاب کنید"
                  />
                  <Input.File
                    inputProps={{
                      name: "urls[hq]",
                      accept: "video/*",
                    }}
                    fileCover={{
                      inputProps: {
                        name: "cover",
                      },
                      onChange: (cover) => {
                        setItem({ cover: cover.url });
                      },
                    }}
                    fileDuration={{
                      inputProps: {
                        name: "duration",
                        defaultValue: item?.duration,
                      },
                    }}
                    labelProps={{ children: "فایل با کیفیت عالی(HQ)" }}
                    placeholder="فایل را انتخاب کنید"
                  />
                </>
              ) : (
                <>
                  <Input.File
                    inputProps={{
                      name: "urls[normal]",
                      accept: "audio/*",
                    }}
                    fileDuration={{
                      inputProps: {
                        name: "duration",
                        defaultValue: item?.duration,
                      },
                    }}
                    labelProps={{ children: "فایل با کیفیت معمولی" }}
                    placeholder="فایل را انتخاب کنید"
                  />
                  <Input.File
                    inputProps={{
                      name: "urls[hq]",
                      accept: "audio/*",
                    }}
                    fileDuration={{
                      inputProps: {
                        name: "duration",
                        defaultValue: item?.duration,
                      },
                    }}
                    labelProps={{ children: "فایل با کیفیت عالی(HQ)" }}
                    placeholder="فایل را انتخاب کنید"
                  />
                </>
              )}

              <Input.Select
                url="/manager/musics/create?select=artist"
                name="artist_id"
                placeHolder="لطفا یک مورد را انتخاب کنید"
                labelProps={{
                  children: "انتخاب خواننده موزیک",
                }}
                defaultValue={item?.artist_id}
              />
              <Input.Select
                url="/manager/musics/create?select=category"
                name="category_id"
                placeHolder="لطفا یک مورد را انتخاب کنید"
                labelProps={{
                  children: "انتخاب دسته بندی موزیک",
                }}
                defaultValue={item?.category_id}
              />
              <Input.Defualt
                inputProps={{
                  name: "info[arranger]",
                  placeholder: "نام تنظیم کننده را وارد نمایید",
                  defaultValue: item?.info?.arranger,
                }}
                labelProps={{ children: "تنظیم کننده" }}
              />
              <Input.Defualt
                inputProps={{
                  name: "info[composer]",
                  placeholder: "نام اهنگساز زمان را وارد نمایید",
                  defaultValue: item?.info?.composer,
                }}
                labelProps={{ children: "اهنگ ساز" }}
              />
              <Input.Defualt
                inputProps={{
                  name: "info[songwriter]",
                  placeholder: " نام ترانه سرا را وارد نمایید",
                  defaultValue: item?.info?.songwriter,
                }}
                labelProps={{ children: "ترانه سرا" }}
              />
              <Input.Defualt
                inputProps={{
                  name: "info[release_year]",
                  placeholder: "سال انتشار را وارد نمایید",
                  defaultValue: item?.info?.release_year,
                }}
                labelProps={{ children: "سال انتشار" }}
              />
              {type == "video" ? (
                <Input.Select
                  url="/manager/musics/create?select=music"
                  name="song_id"
                  placeHolder="لطفا یک مورد را انتخاب کنید"
                  labelProps={{
                    children: "آهنگ موزیک ویدئو ( اختیاری )",
                  }}
                  defaultValue={item?.song_id}
                />
              ) : null}
              <Input.Textarea
                inputProps={{
                  name: "seo[title]",
                  placeholder: "متن را وارد نمایید",
                  defaultValue: item?.seo?.title,
                }}
                labelProps={{ children: "عنوان صفحه" }}
              />
              <Input.Select
                options={[
                  {
                    label: "غیر فعال",
                    value: 0,
                  },
                  {
                    label: "فعال",
                    value: 1,
                  },
                ]}
                name="status"
                placeHolder="لطفا یک مورد را انتخاب کنید"
                labelProps={{
                  children: "وضعیت",
                }}
                defaultValue={item ? item?.status : 1}
              />
              <Input.Select
                options={[
                  {
                    label: "نمایش داده نشود",
                    value: 0,
                  },
                  {
                    label: "نمایش داده شود ",
                    value: 1,
                  },
                ]}
                name="home_visibility"
                placeHolder="لطفا یک مورد را انتخاب کنید"
                labelProps={{
                  children: "وضعیت نمایش در صفحه",
                }}
                defaultValue={item ? item?.home_visibility : 1}
              />
            </div>
            <div className="my-5">
              <SunEditor
                setContents={item?.text}
                setDefaultStyle="min-height:250px; font-family: vazir; font-size: 15px;"
                placeholder="متن موزیک"
                onChange={(value: string) => {
                  const textMusic: any =
                    window.document.getElementById("text-music");
                  if (textMusic) {
                    textMusic.value = value;
                  }
                }}
              />
              <input
                type="hidden"
                name="text"
                id="text-music"
                defaultValue={item?.text}
              />
            </div>
            <div className="my-5">
              <Input.Textarea
                inputProps={{
                  name: "seo[schema]",
                  style: { direction: "ltr", textAlign: "left" },
                  placeholder: "schema code  ...",
                  defaultValue: item?.seo?.schema,
                }}
                labelProps={{ children: "اسکیما " }}
              />
            </div>
            <div className="my-5">
              <Input.Textarea
                inputProps={{
                  name: "seo[description]",
                  placeholder: "متن را وارد نمایید",
                  defaultValue: item?.seo?.description,
                }}
                labelProps={{ children: "توضیحات سئو " }}
              />
            </div>
            <div>
              {type === "video" ? (
                  <div style={{ padding: "1rem", margin: "1rem" }}  className="my-5 border-[2px] my-3 border-white100 flex flex-col gap-4 items-center justify-center border-dashed p-4 rounded-lg">
                    {item?.url.normal ? (
                        <>
                          <div style={{ width: "-webkit-fill-available"}}>
                            کیفیت معمولی
                            <video controls style={{width:"inherit"}}>
                              <source src={item?.url.normal ? item?.url.normal : ""} type="video/mp4" />
                            </video>
                          </div>
                          <br />
                        </>
                    ) : ""}

                    {item?.url.hq ? (
                        <div style={{ width: "-webkit-fill-available"}}>
                          کیفیت خوب
                          <video controls style={{width:"inherit"}}>
                            <source src={item?.url.hq ? item?.url.hq : ""} type="video/mp4" />
                          </video>
                        </div>
                    ) : ""}
                  </div>
              ) : (
                  <div style={{ padding: "1rem", margin: "1rem" }}  className="my-5 border-[2px] my-3 border-white100 flex flex-col gap-4 items-center justify-center border-dashed p-4 rounded-lg">
                    {item?.url.normal ? (
                        <>
                          <div style={{ width: "-webkit-fill-available"}}>
                            کیفیت معمولی
                            <audio controls src={item?.url.normal ? item?.url.normal : ""}  style={{width:"inherit"}}>
                              <source src={item?.url.normal ? item?.url.normal : ""} type="audio/mpeg" />
                            </audio>
                          </div>
                          <br />
                        </>
                    ) : ""}

                    {item?.url.hq ? (
                        <div style={{ width: "-webkit-fill-available"}}>
                          کیفیت خوب
                          <audio controls src={item?.url.hq ? item?.url.hq : ""}  style={{width:"inherit"}}>
                            <source src={item?.url.hq ? item?.url.hq : ""} type="audio/mpeg" />
                          </audio>
                        </div>
                    ) : ""}
                  </div>
              )}
            </div>
            <div className="my-5">
              <Input.Preview
                defaultValue={item?.cover}
                labelProps={{ children: "تصویر کاور" }}
                inputProps={{ name: "cover" }}
              />
            </div>
          </Form>
        </div>
      )}
    </Fragment>
  );
};

export default Creation;
