import { useEffect, useState } from "react";
import useRequest from "./request";
import toast from "react-hot-toast";

export interface ApiProps {
  list: Array<any>;
  item: any;
  loading: boolean;
  progress: number;
  paginate: { links: Array<any> };
  index: Function;
  find: Function;
  /*findWithResponse: Function;*/
  store: Function;
  update: Function;
  destroy: Function;
  setItem: Function;
  setList: Function;
  setLoading: Function;
}
export const useApi = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [list, setList] = useState<Array<any>>([]);
  const [item, setItem] = useState<any>();
  const [progress, setProgress] = useState<number>(0);
  const [paginate, setPaginate] = useState<{ links: [] }>({ links: [] });
  const request = useRequest();

  useEffect(() => {
    return () => {
      setList([]);
      setPaginate({ links: [] });
      setItem(null);
    };
  }, []);

  const index = (url: string) => {
    setLoading(true);
    request.send({ method: "get", url }).then((response) => {
      setLoading(false);
      if (response) {
        if (response.total && response.per_page && response.current_page) {
          setPaginate({ links: response.links });
        }

        setList(response?.data);
      }
    });
  };

  const find = (url: string, id: string = "") => {
    setLoading(true);
    let combinUrl = url;
    if (id) {
      combinUrl += "/" + id;
    }
    request.send({ method: "get", url: combinUrl }).then((response) => {
      setLoading(false);

      if (response) {
        setItem(response);
      }
    });
  };
  /*const findWithResponse = async <T = any>(url: string, id: string = ""): Promise<T> => {
    setLoading(true);
    let combinUrl = url;
    if (id) {
      combinUrl += "/" + id;
    }
    try {
      const response = await request.send({ method: "get", url: combinUrl });
      if (response) {
        setItem(response);
        return response;
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
    return {} as T; // Return an empty object of type T
  };*/
  const store = async (url: string, data: FormData) => {
    await request
      .send({
        method: "post",
        data,
        url,
        onUploadProgress: (e) => {
          if (e.total) {
            const value = Math.round((100 * e.loaded) / e.total);
            setProgress(value);
          }
        },
      })
      .then((response) => {
        setProgress(0);
        if (response) {
          toast.success("درخواست با موفقیت انجام شد");
        }
      });
  };

  const update = async (url: string, data: FormData, id: string) => {
    await request
      .send({
        method: "post",
        data,
        url: url + "/" + id,
        onUploadProgress: (e) => {
          if (e.total) {
            const value = Math.round((100 * e.loaded) / e.total);
            setProgress(value);
          }
        },
      })
      .then((response) => {
        if (response) {
          toast.success("درخواست با موفقیت انجام شد");
        }
      });
  };

  const destroy = async (url: string, id: string) => {
    await request
      .send({ method: "delete", url: url + "/" + id })
      .then((response) => {
        if (response) {
          let newList = [...list].filter((x) => x.id != id);
          setList(newList);
        }
      });
  };

  return {
    paginate,
    list,
    item,
    loading,
    progress,
    index,
    find,
    /*findWithResponse,*/
    store,
    update,
    destroy,
    setItem,
    setList,
    setLoading,
  };
};
